<template>
  <v-card class="ma-4">
    <v-list>
      <v-list-item v-for="{ field, value } in fields" :key="field">
        <v-list-item-content>
          <v-list-item-subtitle>{{ field }}</v-list-item-subtitle>
          <v-list-item-title>{{ value }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const fields = computed(() => {
      const arr = []

      for (const field in user.value) {
        arr.push({ field, value: user.value[field] })
      }

      return arr
    })

    return {
      user,
      fields
    }
  }
}
</script>
